<template>
  <v-form class="container" ref="form" v-model="valid" lazy-validation>
    <v-card
      outlined
      v-if="country && (isInRole('11') || isInRole('12'))"
      class=""
    >
      <v-card-title> </v-card-title>
      <v-card flat class="pa-5">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="country.countryName"
                  :label="$t('country.countryName')"
                  :rules="rules"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-card flat>
                  <v-card-actions
                    class="elevation-1"
                    :style="
                      $vuetify.theme.isDark
                        ? 'background-color:#182e32'
                        : 'background-color:whitesmoke'
                    "
                  >
                    <v-row class="ma-2 2" no-gutters elevation="5">
                      <v-col style="font-size: 20px" class="button mt-2">
                        {{ $t("city.cities") }}
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              dark
                              class="float-right"
                              @click="addItem"
                            >
                              <v-icon> mdi-plus</v-icon>
                            </v-btn>
                          </template>

                          <span class="">
                            {{ $t("city.addCity") }}
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <!-- </div> -->
                  </v-card-actions>
                  <v-divider />
                  <v-card-text>
                    <v-list>
                      <v-list-item v-for="(item, i) in cities" :key="i">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  :label="$t('city.cityName')"
                                  v-model="item.cityName"
                                  :rules="rules"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="removeItem(i)"
                                icon
                                color="red lighten-2"
                              >
                                <v-icon> mdi-close </v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ $t("remove") }}
                            </span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                  <v-divider></v-divider>
                  <!-- </v-virtual-scroll> -->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <v-btn
          color="green darken-1"
          class="white--text"
          @click="save"
          :disabled="!valid"
          :min-width="100"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn to="/countries" color="" text>
          {{ $t("backToList") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: [],

  data() {
    return {
      guid: this.$route.params.guid,

      selectedItem: 1,

      valid: true,
      country: {},
      cities: [],
      items: [],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    if (this.notEmptyGuid()) {
      this.$axios
        .get("country/Get?guid=" + this.guid)
        .then((response) => {
          this.country = response.data.data;
          this.cities = response.data.data.cities;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },

  watch: {
    // groupItems(val) {
    //   console.log(val);
    // },
  },

  computed: {},
  methods: {
    notEmptyGuid() {
      return this.guid != null && this.guid != undefined && this.guid != "";
    },

    addItem() {
      this.cities.push({});
    },

    removeItem(index) {
      this.cities.splice(index, 1);
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.country.cities = this.cities;
        if (this.notEmptyGuid()) {
          this.$axios
            .post("Country/Update", this.country)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        } else {
          this.$axios
            .post("Country/Add", this.country)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.$router.push({ path: "/countries" });
                // setTimeout(() => {
                //   //this.$router.push({ path: "/countries" });
                //   //window.location.href = "/countries";
                // }, 2000);
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
